
  import BotbuilderApi from 'models/botbuilder_api.coffee'
  import KnowledgeSource from 'models/knowledge_source.coffee'

  import SourceCreatorModal from './source_creator_modal.vue'
  import StatusIcon from './status_icon.vue'
  import { BookOpenIcon, ChevronDownIcon, PlusIcon } from '@heroicons/vue/outline'
  import { Button, DocumentationLink, H3, H4, Label, PageHeader, RadioPanels, Spinner } from 'components/generic'
  import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'

  export default
    data: ->
      sources: []
      settings: {}
      loading: false
      pollingTimeouts: {}
      debounceTimeout: null
      PlusIcon: PlusIcon
      chatHistoryLength: '1'
      formalityOptions: [
        {key: 'formal', label: 'formal', description: 'Let the bot answer in a formal way.'}
        {key: 'informal', label: 'informal', description: 'Let the bot answer in an informal way.'}
      ]
      historyOptions: [
        {key: 'on', label: 'yes', description: 'Take the user’s chat history into account when answering questions, e.g. to ask follow-up questions.'}
        {key: 'off', label: 'no', description: 'Do not take the user’s chat history into account when answering questions. This setting may improve the specificity of answers.'}
      ]

    created: ->
      @loadSettings()
      @loadSources()

    unmounted: ->
      Object.values(@pollingTimeouts).forEach (timeout) -> clearTimeout(timeout)

    methods:
      loadSettings: ->
        @settings = await BotbuilderApi.getKnowledgeSettings()
        @setChatHistoryLength()
      loadSources: ->
        @loading = true
        @sources = await KnowledgeSource.list()
        @loading = false
        @sources.forEach (source) => @updateAndPoll(source)
      setChatHistoryLength: ->
        @chatHistoryLength = if @settings.chatHistoryLength == 0 then 'off' else 'on'
      updateChatHistoryLength: ->
        @settings.chatHistoryLength = if @chatHistoryLength == 'off' then 0 else 1
        @updateSettings()
      goTo: (source) ->
        @$router.push(name: 'knowledgeSource', params: {sourceId: source.id})
      openCreator: ->
        @$refs.creator.open()
      updateAndPoll: (source, data) ->
        source.updateStatusModel(data)
        return if !source.id
        @pollingTimeouts[source.id] = setTimeout((=> @poll(source)), 3000) if source.inProgress
      poll: (source) ->
        return if !source.id
        BotbuilderApi.getKnowledgeSourceStatus(source.id)
          .then (data) => @updateAndPoll(source, data)
      updateSettings: ->
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout((=> BotbuilderApi.updateKnowledgeSettings(@settings)), 1000)

    components:
      BookOpenIcon: BookOpenIcon
      Button: Button
      ChevronDownIcon: ChevronDownIcon
      Disclosure: Disclosure
      DisclosureButton: DisclosureButton
      DisclosurePanel: DisclosurePanel
      DocumentationLink: DocumentationLink
      H3: H3
      H4: H4
      Label: Label
      PageHeader: PageHeader
      PlusIcon: PlusIcon
      RadioPanels: RadioPanels
      SourceCreatorModal: SourceCreatorModal
      Spinner: Spinner
      StatusIcon: StatusIcon
